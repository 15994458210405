<template>
    <div class="login-cnt">
        <div class="title">
            <van-image
                round
                class="logo"
                :src="showUser.avatar"
            ></van-image>
            <p>{{ showUser.nickname }}</p>
        </div>
        <div class="login-success" v-if="finished">
            <van-icon name="passed" color="#07c160" size="22" />
            <p>登录成功</p>
        </div>

        <template v-else>
            <van-button
                :loading="sending"
                :disabled="sending"
                v-if="user"
                round
                block
                type="info"
                @click="handleInnerLogin"
            >确认登录</van-button>
            <wechat-login v-else />
        </template>
    </div>
</template>

<script>
import WechatLogin from '@/components/WechatLogin'
import { getIoServer } from '@/api/live'
import io from 'socket.io-client'
import pick from 'lodash.pick'
import { ACCESS_TOKEN, LOGIN_USER, ACCESS_TOKEN_EXPIRED_AT } from '@/utils/constants'
import { getChurchByDomain } from '@/api/media'

export default {
    name: 'SSO',
    components: { WechatLogin },
    data () {
        return {
            church: {
                name: '',
                logo: ''
            },
            socket: null,
            sending: false,
            user: null,
            finished: false
        }
    },
    computed: {
        showUser () {
            if (this.user) {
                return this.user
            }
            return {
                nickname: this.church.name,
                avatar: this.church.logo
            }
        }
    },
    methods: {
        handleInnerLogin () {
            const expiration = this.$ls.get(ACCESS_TOKEN_EXPIRED_AT)
            this.handleWechatLoginOk({
                user: this.$ls.get(LOGIN_USER),
                token: this.$ls.get(ACCESS_TOKEN),
                expiration: Math.ceil((expiration - new Date().getTime()) / 1000)
            })
        },
        handleWechatLoginOk (data) {
            this.sending = true
            const params = this.$route.query
            params.data = data
            this.socket.emit('OnConfirmLogin', pick(params, ['sid', 'random', 'data']), () => {
                this.sending = false
                this.finished = true
            })
        }
    },
    async created() {
        this.user = this.$ls.get(LOGIN_USER)
        this.$eventBus.$on('login-success', this.handleWechatLoginOk)

        //获取基础信息
        const churchRes = await getChurchByDomain()
        this.church = churchRes.data

        this.sending = true
        getIoServer().then(res => {
            this.socket = io(res.data.dsn + '/sso', { transports: ['websocket'] });
            this.socket.on('connect', () => {
                this.socket.emit('OnQRCodeScanned', this.$route.query)
                this.sending = false
            })
        })
    }
};
</script>

<style lang="less" scoped>
    .login-cnt {
        margin-top: 30%;
        padding: 16px 24px;
        button {
            margin: 10px 0px;
        }
        .title {
            text-align: center; margin-bottom: 10px;
            p {
                color: #323233;
            }
        }

        .logo {
            width: 80px;
            height: 80px;
            margin: 0 auto;
        }
    }
    .login-success {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 20px;
            line-height: 24px;
            margin: 0;
            color: #323233;
            margin-left: 8px;
        }
    }
</style>
