<template>
    <div>
        <van-button
            class="login-button"
            native-type="button"
            @click="handleLogin"
            :icon="icon ? 'https://file4.mogody.com/icons/wechat.png' : ''"
            :round="true"
            :block="true"
            type="primary"
            :loading="sending"
            :disabled="sending"
            v-if="showable"
        >
          <slot name="text">
            微信登录
          </slot>
        </van-button>
        <van-dialog v-model="showQRcode" title="微信扫码登录" show-cancel-button :show-confirm-button="false">
            <div id="qrcode">
                <div class="qrcode-overlay" v-if="scanned">
                    <van-icon name="passed" size="28" color="#07c160" />
                    <p>扫码成功,请在手机上确认登录</p>
                </div>
                <div class="qrcode-overlay" v-if="expired">
                  <van-icon name="close" size="28" color="#ee0a24" />
                  <p>二维码已失效,请重新获取</p>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { login, getAuthRedirectUrl } from '@/api/login'
import { getIoServer } from '@/api/live'
import io from 'socket.io-client'
import QRCode from '@roronoalee/qrcodejs'
import { objectQueryStringify } from '@/utils/util'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'WechatLogin',
    props: {
        icon: {
            required: false,
            type: Boolean,
            default () {
                return false;
            }
        },
        showable: {
            required: false,
            type: Boolean,
            default () {
                return true;
            }
        }
    },
    inject: ['isWeixinBrowser'],
    methods: {
        handleLogin () {
            if (this.isWeixinBrowser) {
                this.handleWechatLogin()
            } else {
                this.handleWechatScanLogin()
            }
        },
        handleWechatScanLogin () {
            this.sending = true
            getIoServer().then(res => {
                this.socket = io(res.data.dsn + '/sso', { transports: ['websocket'] });
                this.socket.on('OnGenerateQRCode', this.generateQRcode)
                this.socket.on('OnLoginSuccess', (data) => {
                    this.$eventBus.$emit('login-success', { ...data })
                })
                this.socket.on('OnQRCodeScannedSuccess', () => {
                    this.scanned = true
                })
                this.socket.on('OnQRCodeExpired', () => {
                    this.expired = true
                })
            })
        },
        handleWechatLogin () {
            this.sending = true
            let url = location.href
            getAuthRedirectUrl({ redirect_url: url }).then(res => {
                window.location.href = res.data.redirect_url
            }).finally(() => {
                this.sending = false
            })
        },
        generateQRcode (data) {
            this.sending = false
            this.showQRcode = true
            this.$nextTick(() => {
                const url = location.origin +'/sso' + objectQueryStringify(data)
                if (!this.qrcode) {
                    this.qrcode = new QRCode(document.getElementById('qrcode'));
                }
                this.qrcode.makeCode(url);
            })

            this.sending = false
        }
    },
    mounted () {
        const query = this.$route.query
        if (query.code && query.state) {
            this.sending = true
            query.type = 'wechat'
            login(query).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$eventBus.$emit('login-success', { ...res.data })
                // this.$router.replace({ query: {} }).catch(err => err)
            }).finally(() => {
                this.sending = false
            })
            return
        } 
        if (this.user.id === 0) {
            // this.handleLogin()
        }
    },
    data () {
        return {
            sending: false,
            showQRcode: false,
            qrcode: null,
            scanned: false,
            expired: false,
            user: {}
        }
    },
    created () {
        this.user = this.$ls.get(LOGIN_USER, { id: 0 })
    }
}
</script>
<style lang="less">
    .login-button {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }
    #qrcode {
        padding: 16px 0px;
        position: relative;

        img {
            margin: 0px auto;
        }
        .qrcode-overlay {
            width: 256px;
            height: 256px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: rgba(255,255,255,0.9);

            p {
                color: #323233;
            }
        }
    }
</style>
